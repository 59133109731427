import React, { Component } from 'react';
import axios from 'axios';
import { API_URL, authHeader } from '../../utils/helper';
import keycloakService from '../../keycloakService';
import MainLayout from '../../layouts/MainLayout';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import InputLabel from '@material-ui/core/InputLabel';
import { CardActionArea, Grid } from '@material-ui/core';
import { Payment } from '@material-ui/icons';
import { StayCurrentPortrait } from '@material-ui/icons';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import BitCoin from '../../assets/images/bitcoin.png';
import { getBalancePoints } from '../../actions/pointsAction';

class BuyExam extends Component {
  constructor() {
    super();
    this.state = {
      paymentLink: null,
      title: '',
      examPaperId: '',
      local: '',
      availableBalance: ''
    };
    this.handleLocalization = this.handleLocalization.bind(this);
  }
  async componentDidMount() {
    const { match, selectedLanguage, getBalancePoints } = this.props;
    const { examPaperId } = match.params;
    const token = await this.getToken();
    axios
      .post(`${API_URL}/payment/pay?examId=${examPaperId}`, {}, authHeader(token))
      .then((responce) => {
        this.setState({ paymentLink: responce.data });
      })
      .catch((error) => {
        console.error(error);
      });

    this.setState({ local: selectedLanguage, examPaperId });
    getBalancePoints();
  }
  componentDidUpdate(preProps) {
    const { selectedLanguage, availaPoints } = this.props;
    if (selectedLanguage !== preProps.selectedLanguage) {
      this.setState({ local: selectedLanguage });
    }
    if (preProps.availaPoints != availaPoints) {
      this.setState({ availableBalance: availaPoints.pointsBalance });
    }
  }
  getToken = async () => {
    const isLoggedIn = keycloakService.isLoggedIn();
    if (isLoggedIn) {
      await keycloakService.updateToken();
      const token = keycloakService.getToken();
      return token;
    }
    return keycloakService.login();
  };
  handlePayment(ButtonType) {
    const { paymentLinkof } = this.state.paymentLink;
    if (ButtonType == 'button1') {
      window.location.href = { paymentLinkof };
    }
  }
  handleLocalization(heading) {
    const { local } = this.state;
    if (heading == 'payments') {
      if (local == 'sinhala') {
        return 'ගෙවීම';
      } else if (local == 'tamil') {
        return 'கொடுப்பனவுகள்';
      } else {
        return 'Payments';
      }
    }
    if (heading == 'selectPayment') {
      if (local == 'sinhala') {
        return 'ගෙවීම් ක්රමයක් තෝරන්න';
      } else if (local == 'tamil') {
        return 'பணம் செலுத்தும் முறையைத் தேர்ந்தெடுக்கவும்';
      } else {
        return 'Select a Payment Method';
      }
    }
    if (heading == 'mobilePay') {
      if (local == 'sinhala') {
        return 'ජංගම දුරකථනයෙන් ගෙවීමට මෙතන ක්ලික් කරන්න';
      } else if (local == 'tamil') {
        return 'மொபைலில் இருந்து பணம் செலுத்த இங்கே கிளிக் செய்யவும்';
      } else {
        return 'Click here to pay from Mobile';
      }
    }
    if (heading == 'creaditDebit') {
      if (local == 'sinhala') {
        return 'ණය කාඩ්පත/ හර කාඩ්පත';
      } else if (local == 'tamil') {
        return 'கிரெடிட் / டெபிட் கார்டு';
      } else {
        return 'Credit / Debit Card';
      }
    }
    if (heading == 'pointsBasePayment') {
      if (local == 'sinhala') {
        return 'Point base payment';
      } else if (local == 'tamil') {
        return 'Point base payment';
      } else {
        return 'Point base payment';
      }
    }
  }
  handleCreaditCard = () => {
    ReactGA.event({
      category: 'User',
      action: 'Chosen Creadit/Debit Card Payment Method'
    });
    window.location.href = this.state.paymentLink;
  };
  handleMobilePay = () => {
    const { history } = this.props;
    const { examPaperId } = this.state;
    ReactGA.event({
      category: 'User',
      action: 'Chosen Mobile Payment Method'
    });
    history.push(`/mobile-pay/${examPaperId}`);
  };
  handlePointBasePay = () => {
    const { history } = this.props;
    const { examPaperId } = this.state;
    history.push(`/point-base-payment/${examPaperId}`);
  };
  render() {
    const { availableBalance } = this.state;
    return (
      <MainLayout index="1">
        <div className="container-fluid px-0 px-md-5 md-5 BuyExam1 BuyExam2 BuyExam2k BuyExam4k">
          <div className="container-header-seperator"></div>
          <h3 className="ml-3" style={{ marginTop: 100, marginLetf: '10px' }}>
            {this.handleLocalization('payment')}
          </h3>
          <h6 className="ml-3">{this.handleLocalization('selectPayment')}</h6>
          <Grid container spacing={1}>
            <Grid item xs={4} sm={4}>
              <Card key="1" style={{ margin: '5' }}>
                <CardActionArea>
                  <Link onClick={this.handleCreaditCard} underline="none">
                    <CardContent>
                      <InputLabel
                        className="pt-3"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <label style={{ color: '#2E8BC0' }}>
                          {this.handleLocalization('creaditDebit')}
                        </label>
                        <Payment style={{ marginLeft: 'auto', color: '#2E8BC0' }} />
                      </InputLabel>
                    </CardContent>
                  </Link>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Card key="1" style={{ margin: '5' }}>
                <CardActionArea>
                  <Link onClick={this.handleMobilePay}>
                    <CardContent>
                      <InputLabel
                        className="pt-3"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <label style={{ color: '#2E8BC0' }}>
                          {this.handleLocalization('mobilePay')}
                        </label>
                        <StayCurrentPortrait style={{ marginLeft: 'auto', color: '#2E8BC0' }} />
                      </InputLabel>
                    </CardContent>
                  </Link>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Card key="1" style={{ margin: '5' }}>
                <CardActionArea>
                  <Link onClick={this.handlePointBasePay} underline="none">
                    <CardContent>
                      <InputLabel
                        className="pt-3"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <label style={{ color: '#2E8BC0' }}>
                          {this.handleLocalization('pointsBasePayment')}
                        </label>
                        <h6 style={{ color: '#2E8BC0', margin: '1px' }}>
                          {availableBalance}(Points)
                        </h6>
                        <img src={BitCoin} style={{ width: '5%', marginLeft: 'auto' }} />
                      </InputLabel>
                    </CardContent>
                  </Link>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </div>
      </MainLayout>
    );
  }
}
const mapStateToProps = (state) => ({
  selectedLanguage: state.language.selectedLanguage,
  availaPoints: state.points.availableBalance
});
const mapDispatchToProps = {
  getBalancePoints
};
export default connect(mapStateToProps, mapDispatchToProps)(BuyExam);
