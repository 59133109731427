import React, { useState, useEffect } from 'react';
import Joyride from 'react-joyride';
import keycloakService from '../../keycloakService';
import { getJoyRideStatus, joyRideAttributeCreation } from '../../actions/userAction'; //,
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function ExamJoyRide(props) {
  const dispatch = useDispatch();
  const [status, setStatus] = useState();
  const [boolStatus, setBoolStatus] = useState(false);
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
      updateSteps();
    }
  }, [language]);
  useEffect(() => {
    if (keycloakService.isLoggedIn()) {
      dispatch(
        getJoyRideStatus((res) => {
          setStatus(res);
        })
      );
    }
    setStatus(true);
  }, []);
  useEffect(() => {
    if (status == '[open]') {
      setBoolStatus(true);
      setState((pre) => ({ ...pre, run: true }));
    }
  }, [status]);
  const [{ run, steps }, setState] = useState({
    run: boolStatus,
    steps: [
      {
        content: <h2>Let&apos;s begin how Buy a Exam</h2>,
        locale: { skip: <strong>SKIP</strong> },
        placement: 'center',
        target: 'body'
      },
      {
        placement: 'bottom',
        target: '#exam-1',
        title: 'Pick a Language'
      },
      {
        placement: 'bottom',
        target: '#exam-2',
        title: 'Pick a Grade'
      },
      {
        placement: 'bottom',
        target: '#exam-3',
        title: 'Pick a Subject'
      },
      {
        placement: 'bottom',
        target: '#exam-4',
        title: 'Pick a Teacher'
      }
    ]
  });
  const updateSteps = () => {
    setState((preValue) => ({
      ...preValue,
      steps: [
        {
          content: <h2>{t('lets_begin')}</h2>,
          locale: { skip: <strong>SKIP</strong> },
          placement: 'center',
          target: 'body'
        },
        {
          placement: 'bottom',
          target: '#exam-1',
          title: t('pick_language')
        },
        {
          placement: 'bottom',
          target: '#exam-2',
          title: t('pick_grade')
        },
        {
          placement: 'bottom',
          target: '#exam-3',
          title: t('pick_subject')
        },
        {
          placement: 'bottom',
          target: '#exam-4',
          title: t('pick_teacher')
        }
      ]
    }));
  };
  const handleCallBack = (data) => {
    console.log('data', data);
    if (data.action == 'close') {
      dispatch(joyRideAttributeCreation());
    }
  };
  return (
    <div>
      <Joyride
        continuous
        callback={handleCallBack}
        run={run}
        steps={steps}
        scrollToFirstStep
        showSkipButton
        disableScrolling
        showProgress
      />
      {props.children}
    </div>
  );
}

export default ExamJoyRide;
