import React, { useEffect } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const index = () => {
  localStorage.setItem('NavName', 'Community');
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  return (
    <MainLayout>
      <div className="pt-5 pt-md-0">
        <h1>{t('community_welcome')}</h1>
        <div>
          <p>&nbsp;</p>
          <p>{t('community_para1')}</p>
          <p>&nbsp;</p>
          <p>{t('community_para2')}</p>
          <p>&nbsp;</p>
          <p>{t('community_para3')}</p>
          <p>&nbsp;</p>
          <p>{t('community_para4')}</p>
          <p>&nbsp;</p>
          <p>{t('community_para5')}</p>
          <p>&nbsp;</p>
          <p>{t('community_para6')}</p>
        </div>
      </div>
    </MainLayout>
  );
};
export default index;
