import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { Toaster } from 'react-hot-toast'; //toast,
import { Card, CardContent, Grid } from '@material-ui/core';
import GoldCoin from '../../assets/images/goldcoin.png';
import { Button } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExamPaperById } from '../../actions/examPaperAction';
import { useTranslation } from 'react-i18next';
import {
  getBalancePoints,
  sendOTPforPointsTopup,
  verifyOTPwithOTPId
} from '../../actions/pointsAction';
import { Spinner } from 'react-bootstrap';

function PointBasePayment() {
  const [showOTPInput, setShowOTPInput] = useState(false);
  const params = useParams('examPaperId');
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [otpId, setOtpId] = useState('');
  const [emailMsg, setEmailMsg] = useState('');
  const exam = useSelector((state) => state.examPaper.examPaperDetails);
  const points = useSelector((state) => state.points.availableBalance);
  const [loading, setLoading] = useState(false);
  const language = useSelector((state) => state.language).selectedLanguage;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(fetchExamPaperById(params.examPaperId));
    dispatch(getBalancePoints());
  }, []);
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const [otp, setOtp] = useState('');
  const handleSendEmail = () => {
    setLoading(true);
    if (exam.sellingPrice > points || exam.sellingPrice == null) {
      setError('Your Points are not enought to buy this Exam');
    } else {
      dispatch(
        sendOTPforPointsTopup(
          params.examPaperId,
          (res) => {
            setOtpId(res);
            setEmailMsg('OTP send your Email Please check');
            setTimeout(() => {
              setEmailMsg('');
            }, 3000);
          },
          setShowOTPInput(!showOTPInput)
        )
      );
      setLoading(false);
    }
  };
  const onOTPVerify = () => {
    dispatch(verifyOTPwithOTPId(otpId, otp), (res) => {
      setError(res);
    });
  };
  return (
    <MainLayout index="1">
      <div className="mt-5">
        <Toaster toastOptions={{ duration: 4000 }} />
      </div>
      <Card style={{ marginLeft: '3%', marginRight: '3%' }}>
        <CardContent>
          <Grid>
            <div className="d-flex align-items-center justify-content-center">
              <div className="w-60 d-flex flex-column gap-5 rounded-lg p-4">
                <div className="text-center font-weight-medium text-white fs-3 mb-6 .col-xs-3">
                  <img src={GoldCoin} style={{ width: '50%' }} />
                  <div>
                    <h1>{t('welcome_points_base')}</h1>
                    <h1>{t('payment_method')}</h1>
                  </div>
                  {otpId != '' && <p style={{ color: 'green' }}>{emailMsg}</p>}
                  {error == 'success' && <p style={{ color: 'green' }}>{t('exam_subscription')}</p>}
                  {error == 'error' && (
                    <p style={{ color: 'red' }}>{t('exam_subscription_failed')}</p>
                  )}
                  {otpId == '' ? (
                    <div className="w-80 d-flex flex-column gap-5 rounded-lg p-4 ">
                      <div className="mt-1 d-flex justify-content-center align-items-center">
                        <Button
                          onClick={handleSendEmail}
                          className="d-flex flex-row align-items-center justify-content-center text-black py-2.5 rounded p-2"
                          style={{
                            width: '300px',
                            backgroundColor: '#17a2b8',
                            border: '#17a2b8'
                          }}
                        >
                          {t('send_otp')}
                          {loading && <Spinner animation="border" size="sm" />}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="w-80 d-flex flex-column gap-5 rounded-lg p-4">
                      <label
                        className="font-weight-bold text-xl text-center mt-1"
                        style={{ color: 'black' }}
                      >
                        {t('enter_otp')}
                      </label>
                      <div className="d-flex justify-content-center align-items-center">
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          otpType="number"
                          disabled={false}
                          autoFocus
                          numInputs={6}
                          renderSeparator={<span> </span>}
                          renderInput={(props) => (
                            <input
                              {...props}
                              style={{
                                width: '40px',
                                height: '40px',
                                fontSize: '16px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                textAlign: 'center',
                                margin: '2px',
                                padding: '5px'
                              }}
                            />
                          )}
                          containerStyle={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px'
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center mt-2">
                        <Button
                          onClick={onOTPVerify}
                          className="d-flex flex-row align-items-center justify-content-center py-2.5 text-black rounded p-2"
                          style={{ width: '300px', backgroundColor: '#17a2b8', border: '#17a2b8' }}
                          disabled={otp.length < 6}
                        >
                          <span>{t('verify_otp')}</span>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </CardContent>
      </Card>
    </MainLayout>
  );
}

export default PointBasePayment;
