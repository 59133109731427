import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL, authHeader } from '../../utils/helper';
import keycloakService from '../../keycloakService';
import MainLayout from '../../layouts/MainLayout';
import { Card, CardContent, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { LocalPhone, Security } from '@material-ui/icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import OtpInput from 'react-otp-input';
import { useParams, useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { fetchExamPaperById } from '../../actions/examPaperAction';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function MobilePay() {
  const [ph, setPh] = useState('');
  const [otp, setOtp] = useState('');
  const [responce, setResponce] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoggedIn = keycloakService.isLoggedIn();
  const params = useParams('examPaperId'); // Use useParams to get examPaperId
  const [loading, setLoading] = useState(false);
  const [phSuccess, setPhSuccess] = useState();
  const { examPaperDetails } = useSelector((state) => state.examPaper);
  const CC_PAYMENT_GATEWAY_CONVENIENCE_CHARGE_PERCENTAGE = 0.2;
  const MOBILE_PAYMENT_GATEWAY_CHARGE_PERCENTAGE = 0.15;
  useEffect(() => {
    dispatch(fetchExamPaperById(params.examPaperId));
  }, [dispatch, params.examPaperId]);

  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const getToken = async () => {
    const isLoggedIn = keycloakService.isLoggedIn();
    if (isLoggedIn) {
      await keycloakService.updateToken();
      const token = keycloakService.getToken();
      return token;
    }
    return keycloakService.login();
  };
  const handlePhoneNum = () => {
    const firstFourDigits = ph.substring(0, 4);
    const containsPattern = firstFourDigits.includes('9476') || firstFourDigits.includes('9477');
    if (containsPattern) {
      onSignup();
    } else {
      setPhSuccess(t('enter_dialog_num'));
    }
  };

  const onSignup = async () => {
    setLoading(true);
    try {
      const token = await getToken();
      const response = await axios.post(
        `${API_URL}/payment/mobile?examId=${params.examPaperId}&tel=${ph}`,
        {},
        authHeader(token)
      );
      toast.success(t('otp_msg'));
      setResponce(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error(t('error_msg'));
      setLoading(false);
    }
  };
  const onOTPVerify = async () => {
    setLoading(true);
    try {
      const token = await getToken();
      const response = await axios.post(
        `${API_URL}/payment/otp?otp=${otp}&paymentId=${responce}`,
        {},
        authHeader(token)
      );
      console.log(response.data);
      toast.success(t('subscribe_msg'));
      setLoading(false);
      history.push('/pending-exams');
    } catch (error) {
      console.error(error);
      toast.error(t('error_msg'));
      setLoading(false);
    }
  };
  const getMobilePrice = (sellingPrice) => {
    let examPriceWithServiceFee =
      sellingPrice / (1 + CC_PAYMENT_GATEWAY_CONVENIENCE_CHARGE_PERCENTAGE);
    return Math.ceil(
      examPriceWithServiceFee + examPriceWithServiceFee * MOBILE_PAYMENT_GATEWAY_CHARGE_PERCENTAGE
    );
  };
  return (
    <MainLayout index="1">
      <div className="mt-5">
        <Toaster toastOptions={{ duration: 4000 }} />
      </div>
      {isLoggedIn ? (
        <Card style={{ marginLeft: '3%', marginRight: '3%' }}>
          <CardContent>
            <Grid>
              <div className="d-flex align-items-center justify-content-center">
                <div className="w-60 d-flex flex-column gap-5 rounded-lg p-4">
                  <div className="text-center font-weight-medium text-white fs-3 mb-6 .col-xs-3">
                    <h1>{t('welcome_to')}</h1>
                    <h1>{t('examAnytime_pay')}</h1>
                    <ul style={{ color: 'black', textAlign: 'left' }}>
                      <li>
                        <strong>{t('subject')}:- </strong>
                        {examPaperDetails.subject}
                      </li>
                      <li>
                        <strong>{t('paper_name')} :- </strong>
                        {examPaperDetails.title}
                      </li>
                      <li>
                        <strong>{t('teacher')} :-</strong> {examPaperDetails.teacherName}
                      </li>
                    </ul>
                    {examPaperDetails.sellingPrice == null ? (
                      <h6>The exam paper price is .....</h6>
                    ) : (
                      <h6>
                        {t('price_around')} {getMobilePrice(examPaperDetails.sellingPrice)}{' '}
                        {t('rupees')}
                      </h6>
                    )}
                  </div>
                  {responce == null ? (
                    <div className="w-80 d-flex flex-column gap-5 rounded-lg p-4">
                      <div
                        className="text-white d-inline-block mx-auto p-3 rounded-circle"
                        style={{ backgroundColor: '#17a2b8' }}
                      >
                        <LocalPhone size={30} />
                      </div>
                      <label className="font-weight-bold text-xl text-center mt-1">
                        {t('enter_mobile_number')}
                      </label>
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className="mb-2">
                          <PhoneInput
                            country={'lk'}
                            value={ph}
                            onChange={setPh}
                            // Add any additional styles you need
                          />
                        </div>
                        <div className="mt-2">
                          <Button
                            onClick={handlePhoneNum}
                            className="d-flex flex-row align-items-center justify-content-center text-black py-2.5 rounded p-2"
                            style={{
                              width: '300px',
                              backgroundColor: '#17a2b8',
                              border: '#17a2b8'
                            }}
                            disabled={ph.length < 11 || 11 < ph.length}
                          >
                            {loading && <Spinner animation="border" size="sm" />}
                            <span>{t('send_code_via_SMS')}</span>
                          </Button>
                        </div>
                      </div>
                      {phSuccess && (
                        <p style={{ color: 'red', fontSize: '24', marginLeft: '20%' }}>
                          {phSuccess}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="w-80 d-flex flex-column gap-5 rounded-lg p-4">
                      <div
                        className="text-white d-inline-block mx-auto p-3 rounded-circle"
                        style={{ backgroundColor: '#17a2b8' }}
                      >
                        <Security size={30} />
                      </div>
                      <label className="font-weight-bold text-xl text-center mt-1">
                        Enter your OTP
                      </label>
                      <div className="d-flex justify-content-center align-items-center">
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          otpType="number"
                          disabled={false}
                          autoFocus
                          numInputs={6}
                          renderSeparator={<span> </span>}
                          renderInput={(props) => (
                            <input
                              {...props}
                              style={{
                                width: '40px',
                                height: '40px',
                                fontSize: '16px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                textAlign: 'center',
                                margin: '2px',
                                padding: '5px'
                              }}
                            />
                          )}
                          containerStyle={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px'
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center mt-2">
                        <Button
                          onClick={onOTPVerify}
                          className="d-flex flex-row align-items-center justify-content-center py-2.5 text-black rounded p-2"
                          style={{ width: '300px', backgroundColor: '#17a2b8', border: '#17a2b8' }}
                          disabled={otp.length < 6}
                        >
                          {loading && <Spinner animation="border" size="sm" />}
                          <span>Verify OTP</span>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        ''
      )}
    </MainLayout>
  );
}

export default MobilePay;
